import { useState } from "react";
import { getAvailabilityDisplay, useCartChange } from "ui";

function App() {
    const translations = window.app.preloadState.translation;
    const state = window.app.preloadState.availability;
    const [availability, setAvailability] = useState(state.initialAvailability);

    useCartChange(() => {
        (async () => {
            const stockAvailability = await getAvailabilityDisplay(state.code);
            if (stockAvailability.availability) {
                setAvailability(stockAvailability.availability);
            }
        })();
    })

    return <span className="body--s d-block warehouseNote firstLetterCapital">
        {translations["availability.deliveryTime"]} <span dangerouslySetInnerHTML={{ __html: availability ?? "" }}></span>
    </span>
}

export default App;
