type Callback<T> = (v: T) => void;

export const Observable = <T>(updater: () => Promise<T> | T) => {
    let value: T | undefined = undefined;

    let subscribers: Callback<T>[] = [];

    const notify = (v: T) => {
        value = v;
        subscribers.forEach(callback => callback(v));
    }

    (async () => {
        try {
            notify(await updater());
        }
        catch (err) {
            console.error(err);
        }
    })();

    return {
        subscribe: (callback: Callback<T>) => {
            subscribers.push(callback);
            if (value) callback(value);
        },
        unsubscribe: (callback: Callback<T>) => {
            subscribers = subscribers.filter(c => c !== callback);
        },
        update: async () => {
            try {
                notify(await updater());
            }
            catch (err) {
                console.error(err);
            }
        }
    }
}

export type Observable<T> = ReturnType<typeof Observable<T>>;